import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["userProjects"];

  onChangeSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.userProjectsTarget.innerHTML = xhr.response;
  }
}
