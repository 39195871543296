import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["add_item", "template"];

  add_association() {
    let content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.add_itemTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove_association(event) {
    let item = event.target.closest(".nested-fields");
    const input = item.querySelector("input[name*='_destroy']");
    if (input) input.value = 1;
    item.style.display = "none";
  }
}
